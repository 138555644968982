import { useStaticQuery, graphql } from 'gatsby'

export const useArchiveCaseStudies = () => {
  const { allContentfulMasterWorkList } = useStaticQuery(
    graphql`
      query {
        allContentfulMasterWorkList(limit: 1) {
            edges {
              node {
                id
                caseStudies {
                    slug
                    title
                    featuredImg {
                      gatsbyImageData(quality: 90, placeholder: DOMINANT_COLOR) 
                      description
                      file {
                        contentType
                        url
                      }
                    }
                }
              }
            }
          }
      }
    `
  )
  return allContentfulMasterWorkList
}
