import React from 'react'
import Layout from '../components/layout'
import SEO from '../components/seo'
import { useArchiveCaseStudies } from '../hooks/use-archives-caseStudies'
import { H1 } from '../components/shared/typography'
import { Section, WorkSection, WorkGrid, WorkItem } from '../components/shared/section'
import ImageVideo from '../components/ImageVideo'
import { Link as GatsbyLink } from 'gatsby'
import styled from '@emotion/styled'
import { breakpoints } from '../styles/variables'
/* eslint-disable max-len */

const CaseStudies = () => {
  const { edges } = useArchiveCaseStudies()
  const caseStudies = edges[0].node.caseStudies
  console.log(edges[0].node.caseStudies)
  const bigOnes = [1, 3, 7, 9, 13, 15, 19, 21, 25, 27, 31, 33, 37, 39, 43]
  const getGrid = index => {
    if (bigOnes.includes(index)) {
      return 'md:col-span-8 md:row-span-2'
    } else {
      return 'md:col-span-4 md:row-span-1'
    }
  }
  return (
    <Layout>
      <SEO title="Work" description="We create brand identities, packaging, digital experiences, and marketing for beauty, personal care, skincare, wellness, food, CPG, nonprofits, social impact, and sustainability initiatives." />

      <div className="md:px-32 lg:px-28 px-8 mt-32 mb-8">
        <h1 className="h1">Our work</h1>
      </div>

      <WorkSection className="fullwidth">
        <div className="md:grid-cols-12 grid w-full h-full grid-flow-row-dense grid-cols-1 gap-4">
          {caseStudies.map((feat, index) => (
            <div key={index} className={`relative w-full h-full ${ getGrid(index) }`}>
              <GatsbyLink to={`/case-study/${ feat.slug }`} className="block w-full h-full group aspect-[5/4]">
                <div className='h-full'>
                  <ImageVideo className="h-full" imgClassName="h-full" file={feat.featuredImg} />
                  <div className="group-hover:opacity-100 absolute inset-0 z-50 flex flex-col items-center justify-center w-full h-full p-4 space-y-4 text-center bg-black opacity-0">
                    <h5 className="h5 text-white">{feat.title}</h5>
                  </div>
                </div>
              </GatsbyLink>
            </div>
          ))}
        </div>
      </WorkSection>
    </Layout>
  )
}

export default CaseStudies
